/* DON'T EDIT THIS FILE: edit original and run build again */ import { BaseOnboardingOptions } from "../../../framework/tenant-handlers/base-onboarding-options.ts";
import { emailChannelType } from "../../../onboarding/channel/channel-types.ts";

class AcavaloresOnboardingOptions extends BaseOnboardingOptions {
  hasAune(): boolean {
    return false;
  }

  hasTerms(): boolean {
    return false;
  }

  getSignaturaValidations(): string[] {
    return [];
  }

  getSupportedChannelTypes(): string[] {
    return [emailChannelType];
  }

  hasHumanDirectShareholders(): boolean {
    return false;
  }

  hasOwnedEntityField(): boolean {
    return false;
  }

  hasEstimatedAmountToInvest(): boolean {
    return true;
  }

  hasAssemblyAttendanceDocument(): boolean {
    return true;
  }

  hasPositionFieldRequired(): boolean {
    return true;
  }

  hasHasNoFinalBeneficiariesField(): boolean {
    return false;
  }

  hasFatca(): "simple" | "full" | "none" {
    return "full";
  }
}

export const acavaloresOnboardingOptions = new AcavaloresOnboardingOptions();
