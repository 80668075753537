/* DON'T EDIT THIS FILE: edit original and run build again */ import { TermsOfUseParams } from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { BaseOnboardingOptions } from "../../../framework/tenant-handlers/base-onboarding-options.ts";
import { emailChannelType } from "../../../onboarding/channel/channel-types.ts";
import { EMAIL } from "../../../onboarding/signatura-document-validations/document-validations.ts";
import { ComponentType } from "react";
import { onboardingTermsOfUseTitle } from "./onboarding-terms-of-use-title.ts";

class UgOnboardingOptions extends BaseOnboardingOptions {
  getTermsOfUseTitle(): string {
    return onboardingTermsOfUseTitle;
  }

  async getTermsOfUse(): Promise<ComponentType<TermsOfUseParams>> {
    return (await import("./onboarding-terms-of-use.tsx")).default;
  }

  hasSignatura(): boolean {
    return true;
  }

  hasSnailMail(): boolean {
    return false;
  }

  getSignaturaValidations(): string[] {
    return [EMAIL];
  }

  hasAune(): boolean {
    return true;
  }

  hasHumanDirectShareholders(): boolean {
    return false;
  }

  getAuneAccountIdCreationMethod(): "automatic" | "manual" | "both" {
    return "both";
  }

  getSupportedChannelTypes(): string[] {
    return [emailChannelType];
  }

  getEmailAfterLabelText(): string | null {
    return (
      "Este mail será detallado en el PUC de Caja de Valores " +
      "(Consulta Online de saldos y movimientos de la cuenta corriente)."
    );
  }

  async getDocumentHeader(): Promise<
    ComponentType<{ agreementLabel: string }>
  > {
    return (await (() => { throw new Error("This file is not meant to be imported in this context.") })()).default;
  }

  hasOwnedEntityField(): boolean {
    return false;
  }

  hasEstimatedAmountToInvest(): boolean {
    return true;
  }

  hasAssemblyAttendanceDocument(): boolean {
    return true;
  }

  hasPositionFieldRequired(): boolean {
    return true;
  }

  hasHasNoFinalBeneficiariesField(): boolean {
    return false;
  }

  getSnailMailSignatureAddress(): string {
    return "Uruguay 2522, Oficina 204, Beccar, San Isidro, Buenos Aires.  CP 1643";
  }

  hasFatca(): "simple" | "full" | "none" {
    return "full";
  }

  hasMatbaRofex(): boolean {
    return false;
  }

  hasSgrComissions(): boolean {
    return false;
  }

  hasOperationsInUsField(): boolean {
    return false;
  }
}

export const ugOnboardingOptions = new UgOnboardingOptions();
