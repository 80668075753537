import * as React from "react";
const SvgLogoBetaSmall = (props) => /* @__PURE__ */ React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 328 446", width: 328, height: 446, ...props }, /* @__PURE__ */ React.createElement("title", null, "logo_beta-svg"), /* @__PURE__ */ React.createElement("style", null, '\n		tspan { white-space:pre } \n		.t0 { font-size: 37px;fill: #424344;font-weight: 400;font-family: "Montserrat-Regular", "Montserrat" } \n		.t1 { font-size: 38px;fill: #424344;font-weight: 400;font-family: "Montserrat-Regular", "Montserrat" } \n		.s2 { fill: #004e8a;stroke: #004e8a;stroke-miterlimit:10;stroke-width: 2 } \n		.s3 { fill: #0096cc } \n		.s4 { fill: #004e8a } \n	'), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("text", { id: "I", style: {
  transform: "matrix(1,0,0,1,964.82,371.25)"
} }, /* @__PURE__ */ React.createElement("tspan", { x: 0, y: 0, className: "t0" }, "I\n")), /* @__PURE__ */ React.createElement("text", { id: "N", style: {
  transform: "matrix(1,0,0,1,979.4,371.25)"
} }, /* @__PURE__ */ React.createElement("tspan", { x: 0, y: 0, className: "t0" }, "N\n")), /* @__PURE__ */ React.createElement("text", { id: "V", style: {
  transform: "matrix(1,0,0,1,1012.55,371.25)"
} }, /* @__PURE__ */ React.createElement("tspan", { x: 0, y: 0, className: "t0" }, "V\n")), /* @__PURE__ */ React.createElement("text", { id: "ERSIONES", style: {
  transform: "matrix(1,0,0,1,1041.2,371.25)"
} }, /* @__PURE__ */ React.createElement("tspan", { x: 0, y: 0, className: "t0" }, "ERSIONES\n"))), /* @__PURE__ */ React.createElement("text", { id: "\\xAE", style: {
  transform: "matrix(1,0,0,1,1383.94,245.18)"
} }, /* @__PURE__ */ React.createElement("tspan", { x: 0, y: 0, className: "t1" }, "\xAE\n")), /* @__PURE__ */ React.createElement("g", null, /* @__PURE__ */ React.createElement("path", { className: "s2", d: "m164.7 441.8c-3.6 0-7.3-1-10.5-2.8l-136.8-79c-6.5-3.8-10.6-10.8-10.6-18.3v-157.9c0-7.5 4.1-14.5 10.6-18.3l136.8-78.9c6.5-3.8 14.6-3.8 21.1 0l24.4 16.4-0.1 4.4-26.5-17.1c-5.2-2.9-11.6-2.9-16.7 0l-136.8 79c-5.2 3-8.4 8.5-8.4 14.5v157.9c0 6 3.2 11.5 8.4 14.5l136.8 79c5.1 3 11.5 3 16.7 0l136.8-79c5.1-3 8.3-8.5 8.3-14.5v-157.9c0-6-3.2-11.5-8.3-14.5l-36.3-21.7 0.1-5.3 38.3 23.2c6.5 3.8 10.6 10.8 10.6 18.3v157.9c0 7.5-4.1 14.5-10.6 18.3l-136.8 79c-3.2 1.8-6.8 2.8-10.5 2.8z" }), /* @__PURE__ */ React.createElement("path", { className: "s3", d: "m65.7 182.4h54v143.9h-54z" }), /* @__PURE__ */ React.createElement("path", { className: "s3", d: "m89.8 154.8h6v52.3h-6z" }), /* @__PURE__ */ React.createElement("path", { className: "s3", d: "m89.6 294h6.3v76.7h-6.3z" }), /* @__PURE__ */ React.createElement("path", { className: "s4", d: "m161.6 190.8h6.1v34.7h-6.1z" }), /* @__PURE__ */ React.createElement("path", { className: "s4", d: "m161.6 294h6.3v112.7h-6.3z" }), /* @__PURE__ */ React.createElement("path", { className: "s4", d: "m137.7 217.6h54v81.1h-54z" }), /* @__PURE__ */ React.createElement("path", { className: "s3", d: "m209.7 82.7h53.9v243h-53.9z" }), /* @__PURE__ */ React.createElement("path", { className: "s3", d: "m233.1 325.5h7.1v45.3h-7.1z" }), /* @__PURE__ */ React.createElement("path", { className: "s3", d: "m233.4 10.7h6.4v80.4h-6.4z" })), /* @__PURE__ */ React.createElement("g", null));
export default SvgLogoBetaSmall;
