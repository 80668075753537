/* DON'T EDIT THIS FILE: edit original and run build again */ import { InvestorTenantHandler } from "../../../framework/tenant-handlers/investor-tenant-handler.ts";
import { cresiumTenant } from "./code.ts";
import { cresiumFullLabel } from "./full-label.ts";
import { cresiumLabel } from "./label.ts";

class CresiumTenantHandler extends InvestorTenantHandler {
  getCode(): string {
    return cresiumTenant;
  }

  getPathLabel(): string | null {
    return "cresium";
  }

  getLabel(): string {
    return cresiumLabel;
  }

  getFullLabel(): string {
    return cresiumFullLabel;
  }

  getOnboardingOptions() {
    return null;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string {
    return "30-71845660-2";
  }

  publishesInMarketplace(): boolean {
    return true;
  }

  supportsRiskMatrix(): boolean {
    return false;
  }

  doProducersSignUpViaMatchfinRealm(): boolean {
    return true;
  }
}

const cresiumTenantHandler = new CresiumTenantHandler();

export default cresiumTenantHandler;
