/* DON'T EDIT THIS FILE: edit original and run build again */ import { useRealmFromUrl } from "../../framework/app-state/app-state-context.ts";
import {
  matchfinSupportEmail,
  matchfinSupportWhatsapp,
  matchfinSupportWhatsappUnformatted,
} from "../../framework/contact-information/matchfin-support.ts";
import { matchfinTenant } from "../../framework/tenant-matchfin-code/matchfin-tenant-code.ts";
import React from "react";
import { MessageBuilderType } from "./message-builder-type.ts";

export const AskForSupportMessage = ({
  askConditional = "el error persiste",
}): React.ReactNode => {
  const realm = useRealmFromUrl();
  if (realm !== matchfinTenant) {
    return null;
  }
  return (
    // we should maybe have an email and whatsapp support for each realm ?
    <>
      Si {askConditional}, por favor comunicate por mail a{" "}
      <a href={"mailto:" + matchfinSupportEmail}>{matchfinSupportEmail}</a> o
      por whatsapp al{" "}
      <a
        href={
          "https://api.whatsapp.com/send?phone=" +
          matchfinSupportWhatsappUnformatted
        }
        target="_blank"
        rel="noopener noreferrer"
      >
        {matchfinSupportWhatsapp}
      </a>
      .
    </>
  );
};

export const DefaultErrorMessageDescription = (): React.ReactNode => (
  <>
    Hubo un problema, por favor volvé a intentar. <AskForSupportMessage />
  </>
);

export const ErrorMessageDescription = ({
  builder = { message: <DefaultErrorMessageDescription />, showCode: true },
  code,
}: {
  builder?: MessageBuilderType;
  code?: string;
}): React.ReactNode => {
  return (
    <>
      {builder.message}
      {builder.showCode && code ? (
        <span className="text-muted">
          <br />
          {"código de error: " + code}
        </span>
      ) : null}
    </>
  );
};

export const DefaultErrorMessageWithDescription = ({
  builder = { message: <DefaultErrorMessageDescription />, showCode: true },
  code,
}: {
  builder?: MessageBuilderType;
  code?: string;
}): React.ReactNode => {
  return (
    <>
      <DefaultErrorMessageDescription />
      <br />
      <br />
      Descripción del error: {builder.message}.
      {builder.showCode && code ? (
        <span className="text-muted">
          <br />
          {"código de error: " + code}
        </span>
      ) : null}
    </>
  );
};
