/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  AssignedEntitiesList,
  AssignedEntitiesListTypes,
  createAssignedEntitiesListPipe,
} from "../../credit/assigned-entities/data-pipe.ts";
import { UserProfile } from "../../framework/auth-profile/schema.ts";
import { mapArrayToObject } from "../../framework/core/map-array-to-object.ts";
import { ascSorter } from "../../framework/core/sort-by-many.ts";
import { WithId } from "../../framework/core/with-id.ts";
import { DataPipe } from "../../framework/data-pipe/data-pipe.ts";
import { getEndClientsCol } from "../../framework/end-client/data.ts";
import { EndClient } from "../../framework/end-client/schema.ts";
import { batchColPipe } from "../../framework/firebase/batch-pipe.ts";
import { docPipe } from "../../framework/firebase/firestore-pipe.ts";
import { firestoreDocumentId } from "../../framework/firebase/firestore-wrappers-types.ts";
import { rolesSortingIndex } from "../../framework/internal-roles/role-extras.ts";
import { getUserRolesDoc } from "../../framework/permission/roles.ts";
import { Roles, RolesMap } from "../../framework/permission/schema.ts";

export const createEndClientsPipe = (
  tenant: string,
  endClientIds: string[]
): DataPipe<WithId<EndClient, "id">[]> =>
  batchColPipe(
    getEndClientsCol().where("tenant", "==", tenant),
    firestoreDocumentId(),
    "in",
    endClientIds,
    "id",
    "createEndClientsPipe",
    15 // this should not matter, but for some reason firebase breaks if the query is larger
  );

const getFilteredEndClientIds = (
  endclients: string[],
  assigned: AssignedEntitiesList
): string[] => {
  let ids = endclients;
  if (assigned?.type === AssignedEntitiesListTypes.partial) {
    const filterBy = mapArrayToObject(assigned.entityIds, (id) => [id, true]);
    ids = ids.filter((id) => filterBy[id]);
  }
  return ids;
};

export const createFilteredEndClientsPipe = (
  profile: UserProfile,
  roles: RolesMap,
  tenant: string,
  endClientIds: string[]
): DataPipe<WithId<EndClient, "id">[]> =>
  createAssignedEntitiesListPipe(profile, roles, tenant).pipeToPipe(
    (assigned) =>
      createEndClientsPipe(
        tenant,
        getFilteredEndClientIds(endClientIds, assigned)
      )
  );

export const getFilteredUserRolesPipe = (
  uid: string,
  filterBy: RolesMap
): DataPipe<RolesMap> =>
  docPipe(getUserRolesDoc(uid)).pipe((roles) =>
    mapArrayToObject(
      (roles?.roles ?? []).filter((role) => filterBy[role]),
      (role) => [role, true]
    )
  );

export const getFilteredUserRolesListPipe = (
  uid: string,
  filterBy: RolesMap
): DataPipe<Roles[]> =>
  docPipe(getUserRolesDoc(uid)).pipe((roles) =>
    (roles?.roles ?? ([] as Roles[]))
      .filter((role) => filterBy[role])
      .sort(ascSorter((role) => rolesSortingIndex[role]))
  );
