/* DON'T EDIT THIS FILE: edit original and run build again */ import { AuthenticationPage } from "../../framework/auth-page/authentication-page.tsx";
import {
  FormSection,
  TitledFormBorder,
} from "../../framework/theme/form-body.tsx";
import { useState } from "react";
import { Button } from "react-bootstrap";
import {
  getRequiredActionIsFirstTime,
  getRequiredActionItems,
  setRequiredActionsIsFirstTime,
} from "./required-actions.front.ts";

type Step = {
  label: string;
  id: string;
};

const getStepIndex = (key: string, steps: Step[]) => {
  const index = steps.findIndex((step) => step.id === key);
  if (index === -1) {
    return 0; // default to start step
  }
  return index;
};

const StartStep = ({
  steps,
  setSkipStart,
}: {
  steps: Step[];
  setSkipStart: (value: boolean) => void;
}) => (
  <TitledFormBorder title="Antes de continuar">
    <FormSection>
      Tenés algunas acciones pendientes a realizar. Te pedimos las completes
      antes de continuar a la aplicación.
      <br />
      <br />
      Acciones pendientes:
      <ul>
        {steps
          .filter((step) => step.label)
          .map((step, index: number) => (
            <li key={index}>{step.label}</li>
          ))}
      </ul>
      <div className="mt-5 fw-bold d-flex justify-content-end">
        <Button
          onClick={() => {
            setRequiredActionsIsFirstTime(false);
            setSkipStart(true);
          }}
          variant="tenant"
        >
          Empezar
        </Button>
      </div>
    </FormSection>
  </TitledFormBorder>
);

export const RequiredActionsBackground = ({
  children,
}: {
  children: React.ReactNode;
}) => (
  <AuthenticationPage>
    <div className="d-flex flex-column align-items-md-center align-items-sm-baseline">
      <div className="text-xxl-compact mb-2 text-center col-lg-8 mb-7" />
      <div className="col-lg-6 col-md-8 col-sm-12">{children}</div>
    </div>
  </AuthenticationPage>
);

export const RequiredActions = ({
  actions,
  startAt,
  status,
}: {
  actions: any;
  startAt?: any;
  status?: any;
}) => {
  const [skipStart, setSkipStart] = useState(false);
  const steps = getRequiredActionItems({ ...actions, [startAt]: true });
  const startIndex = getStepIndex(startAt, steps);
  const shouldShowStartStep =
    getRequiredActionIsFirstTime() && !skipStart && steps.length !== 1;
  const Component = steps[startIndex]?.Component;
  if (startAt) {
    setRequiredActionsIsFirstTime(false);
  } else if (shouldShowStartStep) {
    return (
      <RequiredActionsBackground>
        <StartStep steps={steps} setSkipStart={setSkipStart} />
      </RequiredActionsBackground>
    );
  }
  return (
    <RequiredActionsBackground>
      <Component status={startAt ? status : null} />
    </RequiredActionsBackground>
  );
};
