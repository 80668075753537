/* DON'T EDIT THIS FILE: edit original and run build again */ import { OnboardingOptions } from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { AlycTenantHandler } from "../../../framework/tenant-handlers/alyc-tenant-handler.ts";
import { BaseLineOptions } from "../../../framework/tenant-handlers/base-line-options.ts";
import { ComponentType, SVGProps } from "react";
import { rosvalTenant } from "./code.ts";
import { rosvalFullLabel } from "./full-label.ts";
import { rosvalLabel } from "./label.ts";
import { rosvalOnboardingOptions } from "./rosval-onboarding-options.ts";

class RosvalTenantHandler extends AlycTenantHandler {
  async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>>> {
    // @ts-expect-error the hook is auto generated
    return (await import("./logo_rosval.svg?react")).default;
  }

  async getSidebarPageSmallLogo() {
    return (await import("./sidebar-page-small-logo.tsx")).default;
  }

  getCode(): string {
    return rosvalTenant;
  }

  getPathLabel(): string | null {
    return "rosval";
  }

  getLabel(): string {
    return rosvalLabel;
  }

  getFullLabel(): string {
    return rosvalFullLabel;
  }

  getOnboardingOptions(): OnboardingOptions | null {
    return rosvalOnboardingOptions;
  }

  getLineOptions(): BaseLineOptions | null {
    return null;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getComplianceEmailAddress(): string {
    return "compliance@rosval.com.ar";
  }

  getCuit(): string {
    return "30-69365797-7";
  }

  getCnvRegistryId(): string | null {
    return "39";
  }

  hasSemaphoreSettings(): boolean {
    return false;
  }
}

const rosvalTenantHandler = new RosvalTenantHandler();

export default rosvalTenantHandler;
