/* DON'T EDIT THIS FILE: edit original and run build again */ import { OnboardingOptions } from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { AlycTenantHandler } from "../../../framework/tenant-handlers/alyc-tenant-handler.ts";
import { BaseLineOptions } from "../../../framework/tenant-handlers/base-line-options.ts";
import { ColorScheme } from "../../../framework/theme/color-scheme-type.ts";
import { ComponentType, SVGProps } from "react";
import { zofingenTenant } from "./code.ts";
import { zofingenFullLabel } from "./full-label.ts";
import { zofingenLabel } from "./label.ts";
import { zofingenLineOptions } from "./zofingen-line-options.ts";
import { zofingenOnboardingOptions } from "./zofingen-onboarding-options.ts";

class ZofingenTenantHandler extends AlycTenantHandler {
  async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>>> {
    // @ts-expect-error the hook is auto generated
    return (await import("./logo_zofingen.svg?react")).default;
  }

  async getSidebarPageSmallLogo() {
    return (await import("./sidebar-page-small-logo.tsx")).default;
  }

  getCode(): string {
    return zofingenTenant;
  }

  getPathLabel(): string | null {
    return "zofingen";
  }

  getLabel(): string {
    return zofingenLabel;
  }

  getFullLabel(): string {
    return zofingenFullLabel;
  }

  getLineOptions(): BaseLineOptions | null {
    return zofingenLineOptions;
  }

  getOnboardingOptions(): OnboardingOptions | null {
    return zofingenOnboardingOptions;
  }

  getNotificationsFrom(): string {
    return "no-responder@zofingen.com.ar";
  }

  getComplianceEmailAddress(): string | null {
    return "compliance@zofingen.com.ar";
  }

  getCuit(): string {
    return "30-70750503-2";
  }

  getCnvRegistryId(): string | null {
    return "196";
  }

  publishesInMarketplace(): boolean {
    return true;
  }

  getColors(): ColorScheme {
    return {
      primary: "#D52A34",
      secondary: "#B81D25",
      tertiary: "#AAA7AE",
    };
  }
}

const zofingenTenantHandler = new ZofingenTenantHandler();

export default zofingenTenantHandler;
