/* DON'T EDIT THIS FILE: edit original and run build again */ import { mapArrayToObject } from "../../framework/core/map-array-to-object.ts";
import {
  crossTenantAdminRoles,
  endClientRoles,
  financerRoles,
  matchfinEndClientRoles,
  matchfinSpecificInternalRoles,
} from "../../framework/internal-roles/internal-roles.ts";
import { matchfinTenant } from "../../framework/tenant-matchfin-code/matchfin-tenant-code.ts";
import { roleLabels } from "../../framework/permission/role-labels.ts";
import { Roles, RolesMap } from "../../framework/permission/schema.ts";

export const getExternalRoles = (roles: RolesMap, tenant: string): Roles[] => {
  const out: Roles[] = [...endClientRoles];
  if (tenant === matchfinTenant && roles[Roles.admin]) {
    out.push(...matchfinEndClientRoles, ...financerRoles);
  }
  return out;
};

export const getFinancerRoles = (): Roles[] => Array.from(financerRoles);

export const getEndClientRoles = (): Roles[] => endClientRoles;

export const isRoleCrossTenantAdmin = (role: Roles): boolean =>
  crossTenantAdminRoles.includes(role);

export const isFinancerRole = (role: Roles): boolean => financerRoles.has(role);

export const isEndClientRole = (role: Roles): boolean =>
  endClientRoles.includes(role) || matchfinEndClientRoles.includes(role);

export const isMatchfinSpecificRole = (role: Roles): boolean =>
  matchfinSpecificInternalRoles.includes(role) ||
  matchfinEndClientRoles.includes(role) ||
  financerRoles.has(role);

export const allRoles = Object.keys(roleLabels) as Roles[];

export const rolesSortingIndex = mapArrayToObject(
  allRoles.entries(),
  ([index, role]) => [role as Roles, index]
);
