/* DON'T EDIT THIS FILE: edit original and run build again */ import { SgrTenantHandler } from "../../../framework/tenant-handlers/sgr-tenant-handler.ts";
import { trendsgrTenant } from "./code.ts";
import { trendsgrFullLabel } from "./full-label.ts";
import { trendsgrLabel } from "./label.ts";

class TrendsgrTenantHandler extends SgrTenantHandler {
  getCode(): string {
    return trendsgrTenant;
  }

  getPathLabel(): string | null {
    return "trendsgr";
  }

  getLabel(): string {
    return trendsgrLabel;
  }

  getFullLabel(): string | null {
    return trendsgrFullLabel;
  }

  getOnboardingOptions() {
    return null;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string | null {
    return "30-71664462-2";
  }

  publishesInMarketplace(): boolean {
    return true;
  }

  hasLufe(): boolean {
    return true;
  }
}

const trendsgrTenantHandler = new TrendsgrTenantHandler();

export default trendsgrTenantHandler;
