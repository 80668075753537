/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  UserPrivateProfile,
  UserProfile,
} from "../../framework/auth-profile/schema.ts";
import { isTenantAllowed } from "../../framework/auth/allowed-tenants.ts";
import { isFinancerRole } from "../../framework/internal-roles/role-extras.ts";
import { adminRole } from "../../framework/permission/all-roles.ts";
import {
  canEditOtherUsersProfile,
  canEditOtherUsersRoles,
  canListDisabledUsers,
  canMigrateFinancerUsers,
  canViewTenantProfiles,
} from "../../framework/permission/permissions.ts";
import { Roles, RolesMap } from "../../framework/permission/schema.ts";
import { userHasPermission } from "../../framework/permission/user-has-permission.ts";
import {
  getTenantHandler,
  getTenantHandlerFromEntityId,
} from "../../framework/tenant-collection/tenant.ts";
import { matchfinTenant } from "../../framework/tenant-matchfin-code/matchfin-tenant-code.ts";

export const assertRoleCanBeEdited = (
  userRoles: RolesMap,
  userTenant: string,
  loggedInRoles: RolesMap
): boolean => {
  const couldEditAdmin = !userRoles[adminRole] || loggedInRoles[adminRole];
  return !!(
    couldEditAdmin && canGiveUsersRole(userRoles, userTenant, loggedInRoles)
  );
};

export const canGiveUsersRole = (
  requestedRoles: RolesMap,
  userTenant: string,
  loggedInRoles: RolesMap
): boolean => {
  if (!requestedRoles || !loggedInRoles) {
    throw Error("invalidParameters");
  }
  const possibleRoles =
    getTenantHandler(userTenant).getRolesAssignableByAdmin(loggedInRoles);
  if (possibleRoles.length === 0) {
    return false;
  }
  const validRequestedRoles = possibleRoles.filter(
    (role) => requestedRoles[role]
  );
  const validRequests = validRequestedRoles.length;
  const totalRequests = Object.keys(requestedRoles).length;
  if (validRequests > totalRequests) {
    throw Error("possibleRolesHasRepeatedElements");
  }
  // iff requestedRoles is a subset of possibleRoles
  return totalRequests === validRequests;
};

export const canViewUserProfile = (
  userProfile: UserProfile,
  loggedInProfile: UserProfile,
  loggedInPrivateProfile: UserPrivateProfile | undefined,
  loggedInRoles: RolesMap
): boolean => {
  if (
    !(userProfile && loggedInProfile && loggedInPrivateProfile && loggedInRoles)
  ) {
    throw new Error("invalidParameters");
  }
  return (
    userHasPermission(loggedInRoles, canViewTenantProfiles) &&
    (!userProfile.disabled ||
      userHasPermission(loggedInRoles, canListDisabledUsers)) &&
    isTenantAllowed(
      {
        roles: loggedInRoles,
        profile: loggedInProfile,
        privateProfile: loggedInPrivateProfile,
      },
      userProfile.tenant
    )
  );
};

export const canEditUserProfile = (
  userProfile: UserProfile,
  userRoles: RolesMap,
  loggedInProfile: UserProfile,
  loggedInPrivateProfile: UserPrivateProfile,
  loggedInRoles: RolesMap
): boolean => {
  if (
    !(
      userProfile &&
      userRoles &&
      loggedInProfile &&
      loggedInPrivateProfile &&
      loggedInRoles
    )
  ) {
    throw new Error("invalidParameters");
  }
  return (
    userHasPermission(loggedInRoles, canEditOtherUsersProfile) &&
    isTenantAllowed(
      {
        roles: loggedInRoles,
        profile: loggedInProfile,
        privateProfile: loggedInPrivateProfile,
      },
      userProfile.tenant
    ) &&
    assertRoleCanBeEdited(userRoles, userProfile.tenant, loggedInRoles)
  );
};

export const canEditUsersRoles = (
  userProfile: UserProfile,
  userRoles: RolesMap,
  loggedInProfile: UserProfile,
  loggedInPrivateProfile: UserPrivateProfile,
  loggedInRoles: RolesMap
): boolean => {
  if (
    !(
      userProfile &&
      userRoles &&
      loggedInProfile &&
      loggedInPrivateProfile &&
      loggedInRoles
    )
  ) {
    throw new Error("invalidParameters");
  }
  return (
    userHasPermission(loggedInRoles, canEditOtherUsersRoles) &&
    isTenantAllowed(
      {
        roles: loggedInRoles,
        profile: loggedInProfile,
        privateProfile: loggedInPrivateProfile,
      },
      userProfile.tenant
    ) &&
    assertRoleCanBeEdited(userRoles, userProfile.tenant, loggedInRoles)
  );
};

export const canMigrateUserToCounterpartyTenant = (
  userProfile: UserProfile,
  userRoles: RolesMap,
  loggedInProfile: UserProfile,
  loggedInPrivateProfile: UserPrivateProfile,
  loggedInRoles: RolesMap
): boolean => {
  if (
    !(
      userProfile &&
      userRoles &&
      loggedInProfile &&
      loggedInPrivateProfile &&
      loggedInRoles
    )
  ) {
    throw new Error("invalidParameters");
  }
  return (
    userHasPermission(loggedInRoles, canMigrateFinancerUsers) &&
    isTenantAllowed(
      {
        roles: loggedInRoles,
        profile: loggedInProfile,
        privateProfile: loggedInPrivateProfile,
      },
      userProfile.tenant
    ) &&
    userProfile.tenant === matchfinTenant &&
    Object.keys(userRoles).some((role) => isFinancerRole(role as Roles)) &&
    !!userProfile.entityIdCompany &&
    getTenantHandlerFromEntityId(userProfile.entityIdCompany, false) !== null
  );
};
