/* DON'T EDIT THIS FILE: edit original and run build again */ import { OnboardingOptions } from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { AlycTenantHandler } from "../../../framework/tenant-handlers/alyc-tenant-handler.ts";
import { BaseLineOptions } from "../../../framework/tenant-handlers/base-line-options.ts";
import { ColorScheme } from "../../../framework/theme/color-scheme-type.ts";
import { ComponentType, SVGProps } from "react";
import { multiploBursatilTenant } from "./code.ts";
import { multiploBursatilFullLabel } from "./full-label.ts";
import { multiploBursatilLabel } from "./label.ts";
import { multiploBursatilOnboardingOptions } from "./multiplo-bursatil-onboarding-options.ts";

class MultiploBursatilTenantHandler extends AlycTenantHandler {
  async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>>> {
    // @ts-expect-error the hook is auto generated
    return (await import("./logo_multiplo.svg?react")).default;
  }

  async getSidebarPageSmallLogo() {
    return (await import("./sidebar-page-small-logo.tsx")).default;
  }

  getCode(): string {
    return multiploBursatilTenant;
  }

  getPathLabel(): string | null {
    return "multiplobursatil";
  }

  getLabel(): string {
    return multiploBursatilLabel;
  }

  getFullLabel(): string {
    return multiploBursatilFullLabel;
  }

  getOnboardingOptions(): OnboardingOptions | null {
    return multiploBursatilOnboardingOptions;
  }

  getLineOptions(): BaseLineOptions | null {
    return null;
  }

  getNotificationsFrom(): string {
    return "no-responder@multiplobursatil.com.ar";
  }

  getComplianceEmailAddress(): string | null {
    return "ricardoromero@multiplobursatil.com.ar ";
  }

  getCuit(): string {
    return "33-71489446-9";
  }

  getCnvRegistryId(): string | null {
    return "613";
  }

  getColors(): ColorScheme {
    return {
      primary: "#375d81",
      secondary: "#21384d",
      tertiary: "#F8F8F8",
    };
  }
}

const multiploBursatilTenantHandler = new MultiploBursatilTenantHandler();

export default multiploBursatilTenantHandler;
