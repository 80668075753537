/* DON'T EDIT THIS FILE: edit original and run build again */ import { Roles } from "../../framework/permission/schema.ts";

export const commonInternalRoles = [
  Roles.tenantAdmin,
  Roles.complianceOfficer,
  Roles.analyst,
  Roles.salesManager,
  Roles.sales,
  Roles.backOffice,
  Roles.trader,
  Roles.headOfProducers,
  Roles.producer,
];

export const fciSpecificInternalRoles = [Roles.portfolioManager];

export const matchfinSpecificInternalRoles = [
  Roles.matchfinAnalyst,
  Roles.matchfinSales,
];

export const financerRoles = new Set([
  Roles.investor,
  Roles.guestSgr,
  Roles.guestBank,
]);

export const endClientRoles = [Roles.endClientUser];

export const matchfinEndClientRoles = [Roles.matchfinEndClientUser];

export const crossTenantAdminRoles = [Roles.admin];
