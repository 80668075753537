/* DON'T EDIT THIS FILE: edit original and run build again */ import {
  BackgroundCheckResponse,
  entityFoundResponse,
} from "../../credit/background-check/background-check-types.ts";
import { BaseFolder } from "../../credit/base-folder/base-folder-type.ts";
import { findInlineEntityByRecord } from "../../credit/entity/inline-entity.ts";
import { Gender } from "../../credit/gender/genders.ts";
import { callFirebaseFunction } from "../../framework/firebase/call-firebase-function.ts";
import { getEntityInfoCallName } from "./get-entity-info-call-signature.ts";

export const getEntityInfo = async ({
  baseFolder,
  country,
  foreignIdNumber,
  cuit,
  dni,
  gender,
}: {
  baseFolder?: BaseFolder;
  country?: string;
  foreignIdNumber?: string;
  cuit?: string;
  dni?: string;
  gender?: Gender;
}): Promise<BackgroundCheckResponse> => {
  if (!cuit && !dni && (!country || !foreignIdNumber)) {
    throw new Error("missingEntityInfoParms");
  }
  if (baseFolder) {
    const inlineEntity = findInlineEntityByRecord(baseFolder, {
      cuit,
      dni,
      country,
      foreignIdNumber,
    });
    if (inlineEntity) {
      return {
        type: entityFoundResponse,
        data: inlineEntity,
      };
    }
  }
  return await callFirebaseFunction(getEntityInfoCallName, {
    country: country ?? null,
    foreignIdNumber: foreignIdNumber ?? null,
    cuit: cuit ?? null,
    dni: dni ?? null,
    gender: gender ?? null,
  });
};
