/* DON'T EDIT THIS FILE: edit original and run build again */ import { OnboardingOptions } from "../../../framework/tenant-handler-type/onboarding-options.ts";
import { AlycTenantHandler } from "../../../framework/tenant-handlers/alyc-tenant-handler.ts";
import { BaseLineOptions } from "../../../framework/tenant-handlers/base-line-options.ts";
import { ColorScheme } from "../../../framework/theme/color-scheme-type.ts";
import { ComponentType, SVGProps } from "react";
import { betaOnboardingOptions } from "./beta-onboarding-options.ts";
import { betaTenant } from "./code.ts";
import { betaFullLabel } from "./full-label.ts";
import { betaLabel } from "./label.ts";

class BetaTenantHandler extends AlycTenantHandler {
  async getAuthPageLogo(): Promise<ComponentType<SVGProps<SVGElement>>> {
    // @ts-expect-error the hook is auto generated
    return (await import("./logo_beta.svg?react")).default;
  }

  async getSidebarPageSmallLogo() {
    return (await import("./sidebar-page-small-logo.tsx")).default;
  }

  getCode(): string {
    return betaTenant;
  }

  getPathLabel(): string | null {
    return "beta";
  }

  getLabel(): string {
    return betaLabel;
  }

  getFullLabel(): string {
    return betaFullLabel;
  }

  getOnboardingOptions(): OnboardingOptions | null {
    return betaOnboardingOptions;
  }

  getLineOptions(): BaseLineOptions | null {
    return null;
  }

  getNotificationsFrom(): string {
    return "no-responder@betagroupinversiones.com.ar";
  }

  getComplianceEmailAddress(): string | null {
    return "info@betagroupinversiones.com.ar";
  }

  getCuit(): string {
    return "30-71742528-2";
  }

  getCnvRegistryId(): string | null {
    return "1575";
  }

  getColors(): ColorScheme {
    return {
      primary: "#3399ff",
      secondary: "#1f5c99",
      tertiary: "#3399ff",
    };
  }
}

const betaTenantHandler = new BetaTenantHandler();

export default betaTenantHandler;
