/* DON'T EDIT THIS FILE: edit original and run build again */ import { BankTenantHandler } from "../../../framework/tenant-handlers/bank-tenant-handler.ts";
import { bancomarivaTenant } from "./code.ts";
import { bancomarivaFullLabel } from "./full-label.ts";
import { bancomarivaLabel } from "./label.ts";

class BancomarivaTenantHandler extends BankTenantHandler {
  getCode(): string {
    return bancomarivaTenant;
  }

  getPathLabel(): string | null {
    return "banco-mariva";
  }

  getLabel(): string {
    return bancomarivaLabel;
  }

  getFullLabel(): string {
    return bancomarivaFullLabel;
  }

  getOnboardingOptions() {
    return null;
  }

  getNotificationsFrom(): string {
    return "no-responder@matchfin.ar";
  }

  getCuit(): string {
    return "30-51642044-4";
  }

  publishesInMarketplace(): boolean {
    return true;
  }

  supportsRiskMatrix(): boolean {
    return false;
  }

  doProducersSignUpViaMatchfinRealm(): boolean {
    return true;
  }

  hasOpinionReportSection(): boolean {
    return false;
  }

  hasGrantedLineSection(): boolean {
    return true;
  }
}

const bancomarivaTenantHandler = new BancomarivaTenantHandler();

export default bancomarivaTenantHandler;
