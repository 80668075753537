/* DON'T EDIT THIS FILE: edit original and run build again */ import { sgrUserCompanyTypeId } from "../../framework/user-company-type-const/user-company-type-const.ts";
import { BaseTenantHandler } from "./base-tenant-handler.ts";

export abstract class SgrTenantHandler extends BaseTenantHandler {
  getUserCompanyType(): string {
    return sgrUserCompanyTypeId;
  }

  hasLufe(): boolean {
    return false;
  }
}
