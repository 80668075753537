/* DON'T EDIT THIS FILE: edit original and run build again */ import { BackgroundCheckResponse } from "../../credit/background-check/background-check-types.ts";
import { Gender } from "../../credit/gender/genders.ts";

export const getEntityInfoCallName = "getEntityInfoCall";

export type GetEntityInfoCall = {
  name: typeof getEntityInfoCallName;
  signature: (params: {
    cuit?: string;
    dni?: string;
    country?: string;
    foreignIdNumber?: string;
    gender?: Gender;
  }) => Promise<BackgroundCheckResponse>;
};
