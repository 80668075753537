/* DON'T EDIT THIS FILE: edit original and run build again */ import { AlycTenantHandler } from "../../../framework/tenant-handlers/alyc-tenant-handler.ts";
import { acavaloresOnboardingOptions } from "./acavalores-onboarding-options.ts";
import { acavaloresTenant } from "./code.ts";
import { acavaloresFullLabel } from "./full-label.ts";
import { acavaloresLabel } from "./label.ts";
import { acavaloresOfficeBranches } from "./office-branch.ts";

class AcavaloresTenantHandler extends AlycTenantHandler {
  getOnboardingOptions() {
    return acavaloresOnboardingOptions;
  }

  getCode() {
    return acavaloresTenant;
  }

  getPathLabel() {
    return "aca-valores";
  }

  getLabel() {
    return acavaloresLabel;
  }

  getFullLabel() {
    return acavaloresFullLabel;
  }

  hasOpinionReportSection(): boolean {
    return false;
  }

  getNotificationsFrom() {
    return "no-responder@matchfin.ar";
  }

  getCuit() {
    return "30-71690881-6";
  }

  getCnvRegistryId(): string {
    return "1304";
  }

  publishesInMarketplace() {
    return true;
  }

  getOfficeBranches() {
    return acavaloresOfficeBranches;
  }

  getTraderEmailAddress(): string | null {
    return "operaciones@acavalores.com.ar";
  }

  getBackOfficeEmailAddress(): string | null {
    return "operaciones@acavalores.com.ar";
  }

  getApprovedOrdersEmailAddress(): string | null {
    return "operaciones@acavalores.com.ar";
  }

  getRejectedOrdersEmailAddress(): string | null {
    return "operaciones@acavalores.com.ar";
  }

  supportsOrderRouting(): boolean {
    return true;
  }
}

const acavaloresTenantHandlerInstance = new AcavaloresTenantHandler();

export default acavaloresTenantHandlerInstance;
