/* DON'T EDIT THIS FILE: edit original and run build again */ import { AttachmentRef } from "../../framework/attachment-type/attachment-ref-type.ts";
import { DownloadFileButton } from "../../framework/attachment-ui/attachment.tsx";
import { useIsInPdf } from "../../onboarding/pdf-wrapper/context.ts";
import { Div } from "../../onboarding/pdf-wrapper/pdf-wrapper.tsx";
import { ImagePreview } from "./image-preview.tsx";

export const SingleFileItemWidget = ({
  attachment,
}: {
  attachment: AttachmentRef;
}) => {
  const isInPdf = useIsInPdf();
  return (
    <Div className="flex-grow-1">
      {isInPdf ? (
        <Div>Adjunto: {attachment.originalName}</Div>
      ) : (
        <Div>
          <ImagePreview attachment={attachment} />
          {attachment.source ? (
            <span className="rounded bg-green20 me-1 px-2 text-white">
              {attachment.source}
            </span>
          ) : null}
          <DownloadFileButton attachment={attachment}>
            {attachment.originalName || "Ver documento"}
          </DownloadFileButton>
        </Div>
      )}
    </Div>
  );
};
